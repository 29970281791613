.liststyle {
  position: inherit;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  justify-content: center;
  min-width: 100px;
}

.listitem {
  margin-bottom: 5px;
  font-size: 14px;
}
