.first {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.second {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.align {
  background-color: beige;
}

.fields {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}
